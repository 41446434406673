import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  innerWidth,
  darkGray,
  mainGreen,
  mainWhite,
  mainPink,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/arrow.svg";
import { slugify } from "../components/common/helpers";

const Wrapper = styled.div`
  background: ${mainWhite};

  .about {
    padding-top: 123px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding-top: 150px;
    }
    @media ${screen.small} {
      padding-bottom: 150px;
      display: block;
    }

    &__page-title {
      color: ${darkGray};
      font-weight: 500;
      font-size: 1.25rem;
      max-width: ${innerWidth};
      margin: 0 0 40px 0;
      padding: 0 30px;
      @media ${screen.xsmall} {
        font-size: 1.68rem;
        padding: 0 70px;
      }
      @media ${screen.small} {
        margin: 0 auto 40px auto;
      }
      @media ${screen.medium} {
        font-size: 1.7rem;
      }
      @media ${screen.large} {
        padding: 0;
      }
    }

    .about-flex {
      display: flex;
      max-width: ${innerWidth};
      padding: 0 30px;
      @media ${screen.xsmall} {
        padding: 0 70px;
      }
      @media ${screen.small} {
        margin: 0 auto;
      }
      @media ${screen.large} {
        padding: 0;
      }

      &__img {
        display: none;
        @media ${screen.small} {
          display: block;
          max-width: 600px;
          width: 100%;
          margin-right: 100px;
        }
      }

      &__texts {
        max-width: 600px;
        width: 100%;

        .description {
          color: ${darkGray};

          h1 {
            font-size: 1.6rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.7rem;
              margin-bottom: 30px;
            }
          }

          h2 {
            font-size: 1.5rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.6rem;
              margin-bottom: 30px;
            }
          }
          h3 {
            font-size: 1.4rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.5rem;
              margin-bottom: 30px;
            }
          }

          h4 {
            font-size: 1.3rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
              margin-bottom: 30px;
            }
          }

          h5 {
            font-size: 1.2rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
              margin-bottom: 30px;
            }
          }

          h6 {
            font-size: 1.1rem;
            margin-bottom: 20px;
            @media ${screen.xsmall} {
              font-size: 1.2rem;
              margin-bottom: 30px;
            }
          }

          p {
            font-size: 1rem;
            margin-bottom: 25px;
            @media ${screen.xsmall} {
              font-size: 1.05rem;
              margin-bottom: 30px;
            }
            @media ${screen.medium} {
              font-size: 1.1rem;
            }
          }
        }

        .nav-page {
          display: flex;
          align-items: center;
          color: ${mainPink};
          max-width: 300px;
          margin-top: 42px;
          transition: color 0.19s ease-out;
          margin-bottom: 45px;
          @media ${screen.xsmall} {
            margin-top: 60px;
          }
          @media ${screen.small} {
            margin-bottom: 0;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainGreen};
            }
            &:hover .nav-page__icon svg .arrow-fill {
              fill: ${mainGreen};
            }
          }

          &__label {
            font-size: 1rem;
            font-weight: 500;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
          }

          &__icon {
            svg {
              width: 22px;
              height: 22px;
              margin-left: 12px;
              margin-top: 4px;
              @media ${screen.xsmall} {
                margin-top: 2px;
                margin-left: 18px;
                width: 28px;
                height: 28px;
              }
              .arrow-fill {
                transition: fill 0.19s ease-out;
              }
            }
          }
        }
      }
    }

    &__img-for-sm-screen {
      margin-top: auto;
      @media ${screen.small} {
        display: none;
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const { title, description, featured_image, title_tag, meta_description } =
    data.content.data;

  // latest ride from graphql query
  const latestRideTitle = data.latestRide.edges[0].node.data.title.text;

  const ogUrl = typeof window !== "undefined" && window.location.href;

  return (
    <>
      <SEO
        title={title_tag}
        description={meta_description}
        image={featured_image.url}
        url={ogUrl}
      />
      <Wrapper>
        <div className="about">
          <h1 className="about__page-title">{title.text}</h1>
          <div className="about-flex">
            <div className="about-flex__img">
              <GatsbyImage
                image={featured_image.gatsbyImageData}
                alt={featured_image.alt || "Featured"}
              />
            </div>
            <div className="about-flex__texts">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
              <Link
                className="nav-page"
                to={`/rides/${slugify(latestRideTitle)}/`}
                title={`${latestRideTitle} ride`}
              >
                <span className="nav-page__label">SEE THE LATEST RIDE</span>
                <span className="nav-page__icon">
                  <Arrow />
                </span>
              </Link>
            </div>
          </div>
          <div className="about__img-for-sm-screen">
            <GatsbyImage
              image={featured_image.gatsbyImageData}
              alt={featured_image.alt || "Featured"}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        title {
          text
        }
        description {
          html
        }
        featured_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title_tag
        meta_description
      }
    }
    latestRide: allPrismicRide(
      sort: { fields: first_publication_date, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
